/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import "@fontsource/libre-baskerville" // Defaults to weight 400 with all styles included.
import "@fontsource/rammetto-one"
import FooterStrapi from "components/Footer"
import HeaderStrapi from "components/Header"
import ShopSelectCountryModal from "components/pages/Shop/ShopSelectCountryModal"
import SEO from "components/SEO"
import Loader from "components/UI/Loader"
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
// @ts-ignore
import { CartProvider, useShoppingCart } from "use-shopping-cart"
import config from "../../config"
import useEventListener from "../hooks/useEventListener"
import { IPLocationProvider } from "../providers/IPLocationProvider"
import {
  ShopLocaleStateProvider,
  useShopLocaleState,
} from "../providers/ShopLocaleProvider"
import BareLayout from "./Bare"

export const PageLoader = styled.div`
  height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  align-items: center;
`

interface ILayoutProps {
  children: any
  seoProps?: any
  // eslint-disable-next-line
  countries?: { [locale: string]: { locale: string; currencyCode: string } }
}

const InnerLayout = ({
  children,
  setCurrency,
  countries,
}: {
  children: any
  setCurrency: (currency: string) => void
  countries?: { [locale: string]: { locale: string; currencyCode: string } }
}) => {
  const { clearCart } = useShoppingCart()
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (refresh) {
      setTimeout(() => setRefresh(false), 1)
    }
  }, [refresh])

  useEventListener(
    "message",
    e => {
      if (e.data.event !== "SHOP_LOCALE_CHANGED") return
      clearCart()
      setRefresh(true)
      setTimeout(() => {
        setCurrency(
          countries ? countries[e.data.payload.value].currencyCode : "GBP"
        )
      }, 500)
    },
    [setCurrency]
  )

  const child = useMemo(() => !refresh && children, [refresh])

  return child
}

const CartProvidedShop = (props: ILayoutProps) => {
  const { locale, loadingCountry } = useShopLocaleState()
  const [currency, setCurrency] = useState<string | undefined>(
    props.countries && props.countries[locale]?.currencyCode
  )

  if (loadingCountry)
    return (
      <PageLoader>
        <Loader
          size={70}
          color="#662D91"
          placeholder="Retrieving shop data..."
        />
      </PageLoader>
    )

  return (
    <CartProvider
      cartMode="checkout-session"
      stripe={config.shopStripeKey}
      currency={currency || "USD"}
    >
      <InnerLayout {...props} setCurrency={currency => setCurrency(currency)} />
    </CartProvider>
  )
}

CartProvidedShop.defaultProps = {
  countries: [],
  seoProps: {},
}

const ProvidedHeader = (props: { countries: Strapi.Country[] }) => {
  const { locale, onLanguageChanged, failedToDetectCountry, manuallySet } =
    useShopLocaleState()
  const [languageModalDisplayed, setLanguageModalDisplayed] = useState(false)

  useEffect(() => {
    if (failedToDetectCountry && !manuallySet) {
      setLanguageModalDisplayed(true)
    }
  }, [failedToDetectCountry, manuallySet])

  const COUNTRIES = props.countries.reduce((acc, p) => {
    acc[p.locale] = p
    return acc
  }, {})

  return (
    <>
      {languageModalDisplayed && (
        <ShopSelectCountryModal
          countries={props.countries}
          dismiss={() => setLanguageModalDisplayed(false)}
        />
      )}
      <HeaderStrapi
        availableLangs={Object.keys(COUNTRIES)}
        languageDropdownOptions={{
          noTarget: true,
          onChange: onLanguageChanged,
        }}
        locale={locale}
      />
    </>
  )
}

const Layout = ({ children, seoProps }: ILayoutProps) => {
  const { allStrapiCountries } = useStaticQuery(graphql`
    {
      allStrapiCountries {
        edges {
          node {
            locale
            name
            code
            currencyCode
          }
        }
      }
    }
  `)

  const COUNTRIES = allStrapiCountries.edges.reduce((acc, { node }) => {
    acc[node.locale] = node
    return acc
  }, {})

  return (
    <BareLayout>
      <SEO
        {...(seoProps || {})}
        image={
          seoProps && typeof seoProps.image === "object"
            ? seoProps.image.url
            : seoProps.image
        }
        titleTemplate="%s | Night Zookeeper"
      />
      <ShopLocaleStateProvider>
        <ProvidedHeader
          countries={allStrapiCountries.edges.map(({ node }) => node)}
        />
        <CartProvidedShop seoProps={null} countries={COUNTRIES}>
          {children}
        </CartProvidedShop>
      </ShopLocaleStateProvider>

      <FooterStrapi />
    </BareLayout>
  )
}

Layout.defaultProps = {
  seoProps: {},
}

export default props => (
  <IPLocationProvider>
    <Layout {...props} />
  </IPLocationProvider>
)
